import Alpine from "alpinejs";
import persist from "@alpinejs/persist";
import focus from "@alpinejs/focus";
import mask from '@alpinejs/mask';

Alpine.plugin(persist);
Alpine.plugin(focus);
Alpine.plugin(mask);

import dateToUtc from "./components/date-to-utc";
import currencies from "./components/currencies";
import simulateTyping from "./components/stimulate-typing";

import sectorManager from "./components/sector-manager";
import activationManager from "./components/activation-manager";
import pricingManager from "./components/pricing-manager";

// General Components
Alpine.data("currencies", currencies);
Alpine.data("dateToUtc", dateToUtc);
Alpine.data("simulateTyping", simulateTyping);

// Register for acgtivation
Alpine.data("sectorManager", sectorManager);
Alpine.data("activationManager", activationManager);
Alpine.data("pricingManager", pricingManager);

window.Alpine = Alpine;

document.addEventListener('DOMContentLoaded', () => Alpine.start());
